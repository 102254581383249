<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" offset="md-4">
            <b-form-input
              type="search"
              class="d-inline-block"
              placeholder="Pesquisar..."
              id="filterInput"
              v-model="filter"
            />
          </b-col>
        </b-form-row>
      </div>

      <b-table
        :sticky-header="true"
        :no-border-collapse="true"
        class="position-relative"
        :items="users"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        empty-filtered-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        striped
        hover
      >
        <template #cell(created_at)="data">
          {{ data.item.created_at | datePT }}
        </template>

        <template #cell(unit)="data">
          {{ data.item.unit }}
        </template>

        <template #cell(crm)="{ item }">
          {{ item.crm ? "Sim" : "Não" }}
        </template>

        <template #cell(full_name)="{ item }">
          <b-media no-body vertical-align="center">
            <b-media-aside class="mr-75">
              <b-avatar size="38" title="TF" />
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-25">
                <b-icon
                  style="width: 9px"
                  icon="circle-fill"
                  :class="item.active === '1' ? 'text-success' : 'text-danger'"
                />
                {{ item.full_name }}
              </h6>
              <b-card-text>{{ item.email }}</b-card-text>
            </b-media-body>
          </b-media>
        </template>

        <template #cell(permission)="data">
          <div class="d-flex-center">
            <i class="bi bi-key mr-50"></i>
            <span>
              {{ data.item.permission }}
            </span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <router-link
              :to="{
                name: 'config-users-edit',
                params: { uuid: data.item.uuid },
              }"
            >
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-2 paginate-area">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class=""> Foram encontrados {{ totalRows }} resultados </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :limit="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="my-1"
              @input="updatePage()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-center"
      centered
      ref="modal-create-user"
      title="Cadastrar Usuário"
      hide-footer
    >
      <b-card-text>
        <b-form
          ref="formCreateUser"
          class="p-0"
          @submit.prevent="submitCreateUser"
        >
          <div class="form-row">
            <div class="col-md-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Nome completo
              </label>
              <div class="form-group">
                <input
                  type="text"
                  v-model="user.full_name"
                  minlength="5"
                  name="full_name"
                  class="form-control"
                  :class="{ 'is-invalid': $v.user.full_name.$error }"
                />
              </div>
            </div>

            <div class="col-md-4">
              <label for=""> Usuário CRM? </label>
              <div class="form-group">
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="user.crm"
                  :options="[
                    { code: 1, title: 'Sim' },
                    { code: 0, title: 'Não' },
                  ]"
                  :searchable="false"
                  :class="{ 'is-invalid': $v.user.crm.$error }"
                >
                </v-select>
              </div>
            </div>

            <div class="col-md-8">
              <label for=""> Unidade </label>
              <div class="form-group">
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="user.unit"
                  :options="optionsUnits"
                  :searchable="false"
                  :class="{ 'is-invalid': $v.user.cellphone.$error }"
                >
                </v-select>
              </div>
            </div>
            <div class="col-md-6">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                E-mail
              </label>
              <div class="form-group">
                <input
                  type="email"
                  v-model="user.email"
                  class="form-control"
                  :class="{ 'is-invalid': $v.user.email.$error }"
                />
              </div>
            </div>
            <div class="col-md-6">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                CPF
              </label>
              <div class="form-group">
                <input
                  v-mask="['###.###.###-##']"
                  type="tel"
                  v-model="user.cpf"
                  :class="{ 'is-invalid': $v.user.cpf.$error }"
                  name="cpf"
                  placeholder="000.000.000-00"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-4">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Celular
              </label>
              <div class="form-group">
                <input
                  v-mask="['(##) #####-####']"
                  type="tel"
                  v-model="user.cellphone"
                  name="cellphone"
                  placeholder="(00) 00000-0000"
                  class="form-control"
                  :class="{ 'is-invalid': $v.user.cellphone.$error }"
                />
              </div>
            </div>
            <div class="col-md-8">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Permissão
              </label>
              <div class="form-group">
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="user.permission"
                  :options="optionsPermissions"
                  :searchable="false"
                  @input="permissionSelected(user.permission)"
                  :class="{ 'is-invalid': $v.user.permission.$error }"
                >
                </v-select>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-6">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Senha
              </label>
              <div class="form-group">
                <input
                  type="text"
                  v-model="user.password"
                  name="password"
                  class="form-control"
                  :class="{ 'is-invalid': $v.user.password.$error }"
                />
              </div>
            </div>
            <div class="col-md-6">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Confirmação de senha
              </label>
              <div class="form-group">
                <input
                  type="text"
                  v-model="user.repeatPassword"
                  class="form-control"
                  :class="{ 'is-invalid': $v.user.repeatPassword.$error }"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="alert alert-warning">
                <p class="alert-body mb-0">
                  Os dados de acesso serão enviados para o
                  <strong>WhatsApp</strong> do usuário. Cadastre o número do
                  celular corretamente.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-row justify-content-end mt-2 pb-1">
            <div class="col-md-2">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                block
                @click="isAddNewUserSidebarActive = false"
              >
                Fechar
              </b-button>
            </div>
            <div class="col-md-4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-2"
                type="submit"
                block
                :disabled="submited"
              >
                <div v-if="submited">
                  <b-spinner small variant="light" /> Verificando...
                </div>
                <div v-else>Cadastrar</div>
              </b-button>
            </div>
          </div>
        </b-form>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BSidebar,
  BForm,
  BFormGroup,
  BFormRow,
  BSpinner,
  BCardText,
  BMediaAside,
  BMediaBody,
  BIcon,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";

export default {
  components: {
    BMediaBody,
    BMediaAside,
    BCardText,
    BSidebar,
    BFormGroup,
    BFormInput,
    BSpinner,
    BCard,
    BRow,
    BForm,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BFormRow,
    vSelect,
    BIcon,
  },
  directives: {
    Ripple,
  },
  validations: {
    user: {
      full_name: {
        required,
        minLength: minLength(5),
      },
      cpf: {
        required,
        minLength: minLength(11),
      },
      cellphone: {
        required,
        minLength: minLength(11),
      },
      email: {
        required,
        email,
      },
      permission: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      crm: {
        required,
      },
      repeatPassword: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  data: () => ({
    optionsUnits: [],
    optionsPermissions: [],
    submited: false,
    filter: null,
    filterOn: [],
    isAddNewUserSidebarActive: false,
    currentPage: 1,
    perPage: 100,
    totalRows: 0,
    rowsTable: 0,
    perPageOptions: [
      {
        title: "100",
        value: 100,
      },
      {
        title: "200",
        value: 200,
      },
    ],
    users: [],
    user: {
      cpf: "",
      full_name: "",
      email: "",
      cellphone: "",
      password: "",
      repeatPassword: "",
      permissions: [],
      unit: "",
    },
    tableColumns: [
      {
        key: "full_name",
        label: "Nome completo",
        sortable: false,
        thStyle: "width: 300px",
      },
      {
        key: "crm",
        label: "Acesso CRM",
        sortable: false,
        thStyle: "width: 100px",
      },
      {
        key: "permission",
        class: "text-center",
        label: "Permissão",
        sortable: false,
      },
      {
        key: "unit",
        class: "text-center",
        label: "Unidade",
        sortable: false,
      },
      {
        key: "created_at",
        label: "Cadastrado",
        sortable: false,
        class: "text-center",
        thStyle: "width: 190px",
      },
      {
        key: "actions",
        label: "",
        thClass: "text-center td-actions",
        thStyle: "width: 40px",
      },
    ],
  }),
  mounted() {
    this.getData();
    this.$root.$on("open:modal-create-user", () => {
      this.isAddNewUserSidebarActive = true;
      this.$refs["modal-create-user"].show();
    });

    this.$root.$on("bv::modal::hidden", () => {
      this.clearForm();
    });

    this.getPermissionsSchool();

    this.$root.$on("input");
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-user");
  },
  methods: {
    permissionSelected(rows) {
      this.item.permissions = rows.permissions;
    },
    async getPermissionsSchool() {
      this.$store.dispatch("Permission/forSelect").then((res) => {
        this.optionsPermissions = res;
      });
    },
    submitCreateUser() {
      this.$v.$touch();
      this.submited = true;
      if (!this.$v.$error) {
        this.$store
          .dispatch("User/create", this.user)
          .then((res) => {
            if (res.status === 200) {
              this.notify(
                "Usuário cadastrado com sucesso!",
                "UserCheckIcon",
                "success",
                "O usuário receberá a senha por e-mail."
              );

              this.isAddNewUserSidebarActive = false;
              this.$refs["modal-create-user"].hide();
              this.getData();
              this.clearForm();
              this.$v.$reset;
            } else {
              this.notifyDefault("error", res.data);
            }
          })
          .catch((err) => {
            this.submited = false;
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    async getData() {
      this.$store
        .dispatch("User/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then((res) => {
          this.users = res.data;
          this.rowsTable = res.data.length;
          this.totalRows = res.meta.total;
          this.currentPage = res.meta.current_page;
        });

      this.$store.dispatch("Unit/forSelect").then((res) => {
        this.optionsUnits = res;
      });
    },
    clearForm() {
      this.user = {
        cpf: "",
        full_name: "",
        email: "",
        cellphone: "",
        password: "",
        repeatPassword: "",
        permissions: [],
        crm: "",
        unit: "",
      };
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar o usuário caso não tenha nenhum cadastro vinculado.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("User/delete", { uuid: uuid }).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "O usuário foi deletado com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
