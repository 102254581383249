var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-form-row",
                { staticClass: "justify-content-between" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-none d-sm-block align-items-center justify-content-left",
                      attrs: { cols: "4", md: "2" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "title",
                          required: "",
                          options: _vm.perPageOptions,
                          clearable: false,
                        },
                        on: { input: _vm.setPerPageSelected },
                        model: {
                          value: _vm.perPage,
                          callback: function ($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "4", offset: "md-4" } },
                    [
                      _c("b-form-input", {
                        staticClass: "d-inline-block",
                        attrs: {
                          type: "search",
                          placeholder: "Pesquisar...",
                          id: "filterInput",
                        },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": true,
              "no-border-collapse": true,
              items: _vm.users,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
              "empty-filtered-text": "Nenhum registro encontrado",
              filter: _vm.filter,
              "filter-included-fields": _vm.filterOn,
              striped: "",
              hover: "",
            },
            on: { filtered: _vm.onFiltered },
            scopedSlots: _vm._u([
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("datePT")(data.item.created_at)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(unit)",
                fn: function (data) {
                  return [_vm._v(" " + _vm._s(data.item.unit) + " ")]
                },
              },
              {
                key: "cell(crm)",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.crm ? "Sim" : "Não") + " ")]
                },
              },
              {
                key: "cell(full_name)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "b-media",
                      { attrs: { "no-body": "", "vertical-align": "center" } },
                      [
                        _c(
                          "b-media-aside",
                          { staticClass: "mr-75" },
                          [
                            _c("b-avatar", {
                              attrs: { size: "38", title: "TF" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-media-body",
                          [
                            _c(
                              "h6",
                              { staticClass: "mb-25" },
                              [
                                _c("b-icon", {
                                  class:
                                    item.active === "1"
                                      ? "text-success"
                                      : "text-danger",
                                  staticStyle: { width: "9px" },
                                  attrs: { icon: "circle-fill" },
                                }),
                                _vm._v(" " + _vm._s(item.full_name) + " "),
                              ],
                              1
                            ),
                            _c("b-card-text", [_vm._v(_vm._s(item.email))]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(permission)",
                fn: function (data) {
                  return [
                    _c("div", { staticClass: "d-flex-center" }, [
                      _c("i", { staticClass: "bi bi-key mr-50" }),
                      _c("span", [
                        _vm._v(" " + _vm._s(data.item.permission) + " "),
                      ]),
                    ]),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-between" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "config-users-edit",
                                params: { uuid: data.item.uuid },
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer cursor",
                              attrs: { icon: "EditIcon", size: "16" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "mx-2 mb-2 mt-2 paginate-area" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-start",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("span", {}, [
                        _vm._v(
                          " Foram encontrados " +
                            _vm._s(_vm.totalRows) +
                            " resultados "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-end",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("b-pagination", {
                        staticClass: "my-1",
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPage,
                          limit: 10,
                          "first-number": "",
                          "last-number": "",
                          "prev-class": "prev-item",
                          "next-class": "next-item",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.updatePage()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prev-text",
                            fn: function () {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronLeftIcon",
                                    size: "18",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "next-text",
                            fn: function () {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronRightIcon",
                                    size: "18",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-create-user",
          attrs: {
            id: "modal-center",
            centered: "",
            title: "Cadastrar Usuário",
            "hide-footer": "",
          },
        },
        [
          _c(
            "b-card-text",
            [
              _c(
                "b-form",
                {
                  ref: "formCreateUser",
                  staticClass: "p-0",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitCreateUser.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Nome completo "),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.full_name,
                              expression: "user.full_name",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.user.full_name.$error },
                          attrs: {
                            type: "text",
                            minlength: "5",
                            name: "full_name",
                          },
                          domProps: { value: _vm.user.full_name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.user,
                                "full_name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Usuário CRM? "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("v-select", {
                            class: { "is-invalid": _vm.$v.user.crm.$error },
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: [
                                { code: 1, title: "Sim" },
                                { code: 0, title: "Não" },
                              ],
                              searchable: false,
                            },
                            model: {
                              value: _vm.user.crm,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "crm", $$v)
                              },
                              expression: "user.crm",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-8" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Unidade "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("v-select", {
                            class: {
                              "is-invalid": _vm.$v.user.cellphone.$error,
                            },
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.optionsUnits,
                              searchable: false,
                            },
                            model: {
                              value: _vm.user.unit,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "unit", $$v)
                              },
                              expression: "user.unit",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" E-mail "),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.email,
                              expression: "user.email",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.user.email.$error },
                          attrs: { type: "email" },
                          domProps: { value: _vm.user.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.user, "email", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" CPF "),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["###.###.###-##"],
                              expression: "['###.###.###-##']",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.cpf,
                              expression: "user.cpf",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.user.cpf.$error },
                          attrs: {
                            type: "tel",
                            name: "cpf",
                            placeholder: "000.000.000-00",
                          },
                          domProps: { value: _vm.user.cpf },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.user, "cpf", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Celular "),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["(##) #####-####"],
                              expression: "['(##) #####-####']",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.cellphone,
                              expression: "user.cellphone",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.user.cellphone.$error },
                          attrs: {
                            type: "tel",
                            name: "cellphone",
                            placeholder: "(00) 00000-0000",
                          },
                          domProps: { value: _vm.user.cellphone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.user,
                                "cellphone",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-8" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Permissão "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("v-select", {
                            class: {
                              "is-invalid": _vm.$v.user.permission.$error,
                            },
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.optionsPermissions,
                              searchable: false,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.permissionSelected(
                                  _vm.user.permission
                                )
                              },
                            },
                            model: {
                              value: _vm.user.permission,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "permission", $$v)
                              },
                              expression: "user.permission",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Senha "),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.password,
                              expression: "user.password",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.user.password.$error },
                          attrs: { type: "text", name: "password" },
                          domProps: { value: _vm.user.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.user,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Confirmação de senha "),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.repeatPassword,
                              expression: "user.repeatPassword",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.$v.user.repeatPassword.$error,
                          },
                          attrs: { type: "text" },
                          domProps: { value: _vm.user.repeatPassword },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.user,
                                "repeatPassword",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "alert alert-warning" }, [
                        _c("p", { staticClass: "alert-body mb-0" }, [
                          _vm._v(" Os dados de acesso serão enviados para o "),
                          _c("strong", [_vm._v("WhatsApp")]),
                          _vm._v(
                            " do usuário. Cadastre o número do celular corretamente. "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "form-row justify-content-end mt-2 pb-1" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-2" },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple.400",
                                  value: "rgba(186, 191, 199, 0.15)",
                                  expression: "'rgba(186, 191, 199, 0.15)'",
                                  modifiers: { 400: true },
                                },
                              ],
                              attrs: {
                                type: "button",
                                variant: "outline-secondary",
                                block: "",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.isAddNewUserSidebarActive = false
                                },
                              },
                            },
                            [_vm._v(" Fechar ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple.400",
                                  value: "rgba(255, 255, 255, 0.15)",
                                  expression: "'rgba(255, 255, 255, 0.15)'",
                                  modifiers: { 400: true },
                                },
                              ],
                              staticClass: "mr-2",
                              attrs: {
                                variant: "success",
                                type: "submit",
                                block: "",
                                disabled: _vm.submited,
                              },
                            },
                            [
                              _vm.submited
                                ? _c(
                                    "div",
                                    [
                                      _c("b-spinner", {
                                        attrs: { small: "", variant: "light" },
                                      }),
                                      _vm._v(" Verificando... "),
                                    ],
                                    1
                                  )
                                : _c("div", [_vm._v("Cadastrar")]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }